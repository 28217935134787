import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _11ef1209 = () => interopDefault(import('../pages/achievement/index.vue' /* webpackChunkName: "pages/achievement/index" */))
const _b9492e7e = () => interopDefault(import('../pages/advertise/index.vue' /* webpackChunkName: "pages/advertise/index" */))
const _6bd25a38 = () => interopDefault(import('../pages/agreement/index.vue' /* webpackChunkName: "pages/agreement/index" */))
const _200d0aba = () => interopDefault(import('../pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _3f6a475a = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _626610dc = () => interopDefault(import('../pages/details/index.vue' /* webpackChunkName: "pages/details/index" */))
const _485c9ecc = () => interopDefault(import('../pages/goals/index.vue' /* webpackChunkName: "pages/goals/index" */))
const _706beaf5 = () => interopDefault(import('../pages/maisoku/index.vue' /* webpackChunkName: "pages/maisoku/index" */))
const _60abdfbe = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _66f6c142 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _63e188f3 = () => interopDefault(import('../pages/project/index.vue' /* webpackChunkName: "pages/project/index" */))
const _1c87fbfd = () => interopDefault(import('../pages/response/index.vue' /* webpackChunkName: "pages/response/index" */))
const _93c5314c = () => interopDefault(import('../pages/achievement/output.vue' /* webpackChunkName: "pages/achievement/output" */))
const _0a13a652 = () => interopDefault(import('../pages/achievement/registration.vue' /* webpackChunkName: "pages/achievement/registration" */))
const _dd34c486 = () => interopDefault(import('../pages/advertise/analysis.vue' /* webpackChunkName: "pages/advertise/analysis" */))
const _25646964 = () => interopDefault(import('../pages/advertise/management.vue' /* webpackChunkName: "pages/advertise/management" */))
const _d3da5ecc = () => interopDefault(import('../pages/advertise/registration.vue' /* webpackChunkName: "pages/advertise/registration" */))
const _477eb481 = () => interopDefault(import('../pages/calendar/registration.vue' /* webpackChunkName: "pages/calendar/registration" */))
const _48c203e1 = () => interopDefault(import('../pages/customer/registration.vue' /* webpackChunkName: "pages/customer/registration" */))
const _1dd89b44 = () => interopDefault(import('../pages/goals/analysis.vue' /* webpackChunkName: "pages/goals/analysis" */))
const _15bfdb18 = () => interopDefault(import('../pages/goals/setting.vue' /* webpackChunkName: "pages/goals/setting" */))
const _85012c68 = () => interopDefault(import('../pages/line/tracking/index.vue' /* webpackChunkName: "pages/line/tracking/index" */))
const _7fa11521 = () => interopDefault(import('../pages/maisoku/export.vue' /* webpackChunkName: "pages/maisoku/export" */))
const _538e19a7 = () => interopDefault(import('../pages/maisoku/template.vue' /* webpackChunkName: "pages/maisoku/template" */))
const _58f161de = () => interopDefault(import('../pages/response/registration.vue' /* webpackChunkName: "pages/response/registration" */))
const _b03f52bc = () => interopDefault(import('../pages/setting/announcement/index.vue' /* webpackChunkName: "pages/setting/announcement/index" */))
const _1572df80 = () => interopDefault(import('../pages/setting/import/index.vue' /* webpackChunkName: "pages/setting/import/index" */))
const _a3803930 = () => interopDefault(import('../pages/setting/mail-template.vue' /* webpackChunkName: "pages/setting/mail-template" */))
const _4758d99f = () => interopDefault(import('../pages/setting/matching-mail.vue' /* webpackChunkName: "pages/setting/matching-mail" */))
const _fda39ce4 = () => interopDefault(import('../pages/setting/shop.vue' /* webpackChunkName: "pages/setting/shop" */))
const _4832a2fb = () => interopDefault(import('../pages/setting/staff/index.vue' /* webpackChunkName: "pages/setting/staff/index" */))
const _d1c48b9a = () => interopDefault(import('../pages/setting/step-mail/index.vue' /* webpackChunkName: "pages/setting/step-mail/index" */))
const _1658bf35 = () => interopDefault(import('../pages/setting/tag/index.vue' /* webpackChunkName: "pages/setting/tag/index" */))
const _3e10458f = () => interopDefault(import('../pages/shop/document-template/index.vue' /* webpackChunkName: "pages/shop/document-template/index" */))
const _64f65cea = () => interopDefault(import('../pages/shop/message/index.vue' /* webpackChunkName: "pages/shop/message/index" */))
const _00323458 = () => interopDefault(import('../pages/message/line/edit.vue' /* webpackChunkName: "pages/message/line/edit" */))
const _f655a92c = () => interopDefault(import('../pages/message/line/log.vue' /* webpackChunkName: "pages/message/line/log" */))
const _d6bfd174 = () => interopDefault(import('../pages/message/line/registration/index.vue' /* webpackChunkName: "pages/message/line/registration/index" */))
const _0a681f46 = () => interopDefault(import('../pages/message/line/select.vue' /* webpackChunkName: "pages/message/line/select" */))
const _2a774f5c = () => interopDefault(import('../pages/message/line/send.vue' /* webpackChunkName: "pages/message/line/send" */))
const _2dd10e2d = () => interopDefault(import('../pages/message/mail/log.vue' /* webpackChunkName: "pages/message/mail/log" */))
const _19b0b0c0 = () => interopDefault(import('../pages/message/mail/registration.vue' /* webpackChunkName: "pages/message/mail/registration" */))
const _3abcbcba = () => interopDefault(import('../pages/message/mail/select.vue' /* webpackChunkName: "pages/message/mail/select" */))
const _6144cdef = () => interopDefault(import('../pages/message/mail/send.vue' /* webpackChunkName: "pages/message/mail/send" */))
const _0efc98ce = () => interopDefault(import('../pages/setting/announcement/registration.vue' /* webpackChunkName: "pages/setting/announcement/registration" */))
const _37cb14fb = () => interopDefault(import('../pages/setting/import/registration.vue' /* webpackChunkName: "pages/setting/import/registration" */))
const _f9f2764e = () => interopDefault(import('../pages/setting/real-estate-agent/property-range.vue' /* webpackChunkName: "pages/setting/real-estate-agent/property-range" */))
const _a2eafdc0 = () => interopDefault(import('../pages/setting/staff/registration.vue' /* webpackChunkName: "pages/setting/staff/registration" */))
const _74ed4a30 = () => interopDefault(import('../pages/setting/step-mail/registration.vue' /* webpackChunkName: "pages/setting/step-mail/registration" */))
const _df813212 = () => interopDefault(import('../pages/message/line/registration/success.vue' /* webpackChunkName: "pages/message/line/registration/success" */))
const _adf1992e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f38aeec8 = () => interopDefault(import('../pages/message/mail/edit/_id.vue' /* webpackChunkName: "pages/message/mail/edit/_id" */))
const _5f078d81 = () => interopDefault(import('../pages/agreement/log/_id.vue' /* webpackChunkName: "pages/agreement/log/_id" */))
const _64e5997c = () => interopDefault(import('../pages/agreement/registration/_id.vue' /* webpackChunkName: "pages/agreement/registration/_id" */))
const _bfb1a546 = () => interopDefault(import('../pages/customer/edit/_id.vue' /* webpackChunkName: "pages/customer/edit/_id" */))
const _ce410bb0 = () => interopDefault(import('../pages/media/registration/_id.vue' /* webpackChunkName: "pages/media/registration/_id" */))
const _5f05245a = () => interopDefault(import('../pages/project/registration/_id.vue' /* webpackChunkName: "pages/project/registration/_id" */))
const _3809fcb7 = () => interopDefault(import('../pages/response/add/_id.vue' /* webpackChunkName: "pages/response/add/_id" */))
const _7777998a = () => interopDefault(import('../pages/setting/announcement/_id.vue' /* webpackChunkName: "pages/setting/announcement/_id" */))
const _68732823 = () => interopDefault(import('../pages/setting/staff/_id.vue' /* webpackChunkName: "pages/setting/staff/_id" */))
const _f1aaa54a = () => interopDefault(import('../pages/setting/step-mail/_id.vue' /* webpackChunkName: "pages/setting/step-mail/_id" */))
const _352adc5c = () => interopDefault(import('../pages/shop/message/_id.vue' /* webpackChunkName: "pages/shop/message/_id" */))
const _30fc02b1 = () => interopDefault(import('../pages/achievement/_id.vue' /* webpackChunkName: "pages/achievement/_id" */))
const _1a443e4c = () => interopDefault(import('../pages/agreement/_id.vue' /* webpackChunkName: "pages/agreement/_id" */))
const _12e7c836 = () => interopDefault(import('../pages/client-history/_id.vue' /* webpackChunkName: "pages/client-history/_id" */))
const _23b65842 = () => interopDefault(import('../pages/customer/_id.vue' /* webpackChunkName: "pages/customer/_id" */))
const _288ab9a6 = () => interopDefault(import('../pages/media/_id.vue' /* webpackChunkName: "pages/media/_id" */))
const _27d9a7ca = () => interopDefault(import('../pages/project/_id.vue' /* webpackChunkName: "pages/project/_id" */))
const _1ca15673 = () => interopDefault(import('../pages/property/_property.vue' /* webpackChunkName: "pages/property/_property" */))
const _7c272f87 = () => interopDefault(import('../pages/property/_property/contact/index.vue' /* webpackChunkName: "pages/property/_property/contact/index" */))
const _12817335 = () => interopDefault(import('../pages/property/_property/contact/confirm.vue' /* webpackChunkName: "pages/property/_property/contact/confirm" */))
const _581ad963 = () => interopDefault(import('../pages/property/_property/contact/send.vue' /* webpackChunkName: "pages/property/_property/contact/send" */))
const _6ceed9a5 = () => interopDefault(import('../pages/response/_id.vue' /* webpackChunkName: "pages/response/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/achievement",
    component: _11ef1209,
    name: "achievement"
  }, {
    path: "/advertise",
    component: _b9492e7e,
    name: "advertise"
  }, {
    path: "/agreement",
    component: _6bd25a38,
    name: "agreement"
  }, {
    path: "/calendar",
    component: _200d0aba,
    name: "calendar"
  }, {
    path: "/customer",
    component: _3f6a475a,
    name: "customer"
  }, {
    path: "/details",
    component: _626610dc,
    name: "details"
  }, {
    path: "/goals",
    component: _485c9ecc,
    name: "goals"
  }, {
    path: "/maisoku",
    component: _706beaf5,
    name: "maisoku"
  }, {
    path: "/media",
    component: _60abdfbe,
    name: "media"
  }, {
    path: "/privacy",
    component: _66f6c142,
    name: "privacy"
  }, {
    path: "/project",
    component: _63e188f3,
    name: "project"
  }, {
    path: "/response",
    component: _1c87fbfd,
    name: "response"
  }, {
    path: "/achievement/output",
    component: _93c5314c,
    name: "achievement-output"
  }, {
    path: "/achievement/registration",
    component: _0a13a652,
    name: "achievement-registration"
  }, {
    path: "/advertise/analysis",
    component: _dd34c486,
    name: "advertise-analysis"
  }, {
    path: "/advertise/management",
    component: _25646964,
    name: "advertise-management"
  }, {
    path: "/advertise/registration",
    component: _d3da5ecc,
    name: "advertise-registration"
  }, {
    path: "/calendar/registration",
    component: _477eb481,
    name: "calendar-registration"
  }, {
    path: "/customer/registration",
    component: _48c203e1,
    name: "customer-registration"
  }, {
    path: "/goals/analysis",
    component: _1dd89b44,
    name: "goals-analysis"
  }, {
    path: "/goals/setting",
    component: _15bfdb18,
    name: "goals-setting"
  }, {
    path: "/line/tracking",
    component: _85012c68,
    name: "line-tracking"
  }, {
    path: "/maisoku/export",
    component: _7fa11521,
    name: "maisoku-export"
  }, {
    path: "/maisoku/template",
    component: _538e19a7,
    name: "maisoku-template"
  }, {
    path: "/response/registration",
    component: _58f161de,
    name: "response-registration"
  }, {
    path: "/setting/announcement",
    component: _b03f52bc,
    name: "setting-announcement"
  }, {
    path: "/setting/import",
    component: _1572df80,
    name: "setting-import"
  }, {
    path: "/setting/mail-template",
    component: _a3803930,
    name: "setting-mail-template"
  }, {
    path: "/setting/matching-mail",
    component: _4758d99f,
    name: "setting-matching-mail"
  }, {
    path: "/setting/shop",
    component: _fda39ce4,
    name: "setting-shop"
  }, {
    path: "/setting/staff",
    component: _4832a2fb,
    name: "setting-staff"
  }, {
    path: "/setting/step-mail",
    component: _d1c48b9a,
    name: "setting-step-mail"
  }, {
    path: "/setting/tag",
    component: _1658bf35,
    name: "setting-tag"
  }, {
    path: "/shop/document-template",
    component: _3e10458f,
    name: "shop-document-template"
  }, {
    path: "/shop/message",
    component: _64f65cea,
    name: "shop-message"
  }, {
    path: "/message/line/edit",
    component: _00323458,
    name: "message-line-edit"
  }, {
    path: "/message/line/log",
    component: _f655a92c,
    name: "message-line-log"
  }, {
    path: "/message/line/registration",
    component: _d6bfd174,
    name: "message-line-registration"
  }, {
    path: "/message/line/select",
    component: _0a681f46,
    name: "message-line-select"
  }, {
    path: "/message/line/send",
    component: _2a774f5c,
    name: "message-line-send"
  }, {
    path: "/message/mail/log",
    component: _2dd10e2d,
    name: "message-mail-log"
  }, {
    path: "/message/mail/registration",
    component: _19b0b0c0,
    name: "message-mail-registration"
  }, {
    path: "/message/mail/select",
    component: _3abcbcba,
    name: "message-mail-select"
  }, {
    path: "/message/mail/send",
    component: _6144cdef,
    name: "message-mail-send"
  }, {
    path: "/setting/announcement/registration",
    component: _0efc98ce,
    name: "setting-announcement-registration"
  }, {
    path: "/setting/import/registration",
    component: _37cb14fb,
    name: "setting-import-registration"
  }, {
    path: "/setting/real-estate-agent/property-range",
    component: _f9f2764e,
    name: "setting-real-estate-agent-property-range"
  }, {
    path: "/setting/staff/registration",
    component: _a2eafdc0,
    name: "setting-staff-registration"
  }, {
    path: "/setting/step-mail/registration",
    component: _74ed4a30,
    name: "setting-step-mail-registration"
  }, {
    path: "/message/line/registration/success",
    component: _df813212,
    name: "message-line-registration-success"
  }, {
    path: "/",
    component: _adf1992e,
    name: "index"
  }, {
    path: "/message/mail/edit/:id?",
    component: _f38aeec8,
    name: "message-mail-edit-id"
  }, {
    path: "/agreement/log/:id?",
    component: _5f078d81,
    name: "agreement-log-id"
  }, {
    path: "/agreement/registration/:id?",
    component: _64e5997c,
    name: "agreement-registration-id"
  }, {
    path: "/customer/edit/:id?",
    component: _bfb1a546,
    name: "customer-edit-id"
  }, {
    path: "/media/registration/:id?",
    component: _ce410bb0,
    name: "media-registration-id"
  }, {
    path: "/project/registration/:id?",
    component: _5f05245a,
    name: "project-registration-id"
  }, {
    path: "/response/add/:id?",
    component: _3809fcb7,
    name: "response-add-id"
  }, {
    path: "/setting/announcement/:id",
    component: _7777998a,
    name: "setting-announcement-id"
  }, {
    path: "/setting/staff/:id",
    component: _68732823,
    name: "setting-staff-id"
  }, {
    path: "/setting/step-mail/:id?",
    component: _f1aaa54a,
    name: "setting-step-mail-id"
  }, {
    path: "/shop/message/:id",
    component: _352adc5c,
    name: "shop-message-id"
  }, {
    path: "/achievement/:id",
    component: _30fc02b1,
    name: "achievement-id"
  }, {
    path: "/agreement/:id",
    component: _1a443e4c,
    name: "agreement-id"
  }, {
    path: "/client-history/:id?",
    component: _12e7c836,
    name: "client-history-id"
  }, {
    path: "/customer/:id",
    component: _23b65842,
    name: "customer-id"
  }, {
    path: "/media/:id",
    component: _288ab9a6,
    name: "media-id"
  }, {
    path: "/project/:id",
    component: _27d9a7ca,
    name: "project-id"
  }, {
    path: "/property/:property?",
    component: _1ca15673,
    name: "property-property",
    children: [{
      path: "contact",
      component: _7c272f87,
      name: "property-property-contact"
    }, {
      path: "contact/confirm",
      component: _12817335,
      name: "property-property-contact-confirm"
    }, {
      path: "contact/send",
      component: _581ad963,
      name: "property-property-contact-send"
    }]
  }, {
    path: "/response/:id",
    component: _6ceed9a5,
    name: "response-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
